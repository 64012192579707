/** @jsx jsx */
import { jsx } from "theme-ui"

const Base = ({ as = "h2", ...props }) => {
  const ComponentType = as
  return <ComponentType sx={{ fontWeight: "400", mt: 0, mb: 4 }} {...props} />
}

const H2 = ({ ...props }) => (
  <Base
    sx={{
      fontSize: [60, 70, 120, , 144],
      fontWeight: "100",
      mb: [30, 60, , , 90],
    }}
    {...props}
  />
)
const H3 = ({ ...props }) => (
  <Base as="h3" sx={{ fontSize: [36, 42, , , 48] }} {...props} />
)
const H4 = ({ ...props }) => (
  <Base as="h4" sx={{ fontSize: [20, 28, , , 36], mb: 48 }} {...props} />
)
const H5 = ({ ...props }) => (
  <Base as="h5" sx={{ fontSize: [18, 21, 24], fontWeight: "700" }} {...props} />
)

const P1 = ({ ...props }) => (
  <Base as="p" sx={{ fontSize: [18, 21, , , 24] }} {...props} />
)
const P2 = ({ ...props }) => <Base as="p" sx={{ fontSize: 18 }} {...props} />

export { H2, H3, H4, H5, P1, P2 }
