/** @jsx jsx */
import { jsx } from "theme-ui"
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa"
import Link from "./Link"

const SocialLinks = props => (
  <div {...props}>
    {[
      {
        icon: FaFacebookF,
        to: "https://www.facebook.com/Vretta",
        title: "Facebook",
      },
      {
        icon: FaTwitter,
        to: "https://twitter.com/vrettainc",
        title: "Twitter",
      },
      {
        icon: FaLinkedinIn,
        to:
          "https://www.linkedin.com/company/vretta?trk=tyah&trkInfo=tarId%3A1414377430768%2Ctas%3Avrett%2Cidx%3A2-1-6",
        title: "LinkedIn",
      },
    ].map((socialItem, iconIndex) => (
      <Link
        to={socialItem.to}
        key={iconIndex}
        sx={{ fontSize: "1.5em", mr: 4 }}
        aria-label={socialItem.title}
      >
        <socialItem.icon sx={iconIndex} />
      </Link>
    ))}
  </div>
)

export default SocialLinks
