/** @jsx jsx */
import { jsx } from "theme-ui"

const Container = ({ fluid, ...props }) => {
  return (
    <div
      sx={{
        mx: "auto",
        px: 3,
        maxWidth: fluid ? `100%` : [`100%`, 540, 720, 960, 1200],
        width: `100%`,
        boxSizing: "border-box",
      }}
      {...props}
    />
  )
}

const Row = ({ ...props }) => {
  return (
    <div
      sx={{
        mx: -2,
        display: "flex",
        flexWrap: "wrap",
      }}
      {...props}
    />
  )
}

const Col = ({ xs, sm, md, lg, xl, ...props }) => {
  const normalize = val => (val ? val * 100 + "%" : null)

  // console.log([n(xs), n(sm), n(md), n(lg), n(xl)])

  return (
    <div
      sx={{
        px: 2,
        width: "100%",
        maxWidth: [
          normalize(xs),
          normalize(sm),
          normalize(md),
          normalize(lg),
          normalize(xl),
        ],

        "& > *": {
          maxWidth: "100%",
        },
      }}
      {...props}
    />
  )
}

export { Container, Row, Col }
