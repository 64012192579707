import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import { Container, Row, Col } from "./Grid"
import { H5, P2 } from "./Typography"
import SocialLinks from "../components/SocialLinks"
import Link from "./Link"
import GatsbyImage from "gatsby-image"

const Location = ({ title, address, ...props }) => {
  return (
    <div sx={{ mb: 4 }} {...props}>
      <H5 sx={{ mb: 2 }}>{title}</H5>
      {address.map(item => (
        <P2 key={item} sx={{ mb: 0, color: "grey", fontWeight: "bold" }}>
          {item}
        </P2>
      ))}
    </div>
  )
}

const legalLinks = [
  {
    text: `Terms & Conditions`,
    to: `https://www.vretta.com/term_service.html`,
  },
  {
    text: `Privacy Statement`,
    to: `https://www.vretta.com/privacy_statement.html`,
  },
  {
    text: `Data Protection`,
    to: `https://www.vretta.com/data_protection.html`,
  },
  { text: `Accessibility Statement`, to: `/accessibility` },
]

const addresses = [
  {
    title: `Canada`,
    address: [`120 Adelaide Street East`, `Toronto, Ontario  M5C 1K9`],
  },
  {
    title: `United Kingdom`,
    address: [`20-22 Wenlock Road`, `London N1 7GU`],
  },
  {
    title: `Luxembourg`,
    address: [`6, rue Tubis`, `L-2629 Luxembourg`],
  },
  {
    title: `USA`,
    address: [`300 - 485 Massachusetts Avenue`, `Cambridge, MA 02139-4018`],
  },
]

const AwardImgCol = ({ fluid, to, ...props }) => {
  return (
    <Col
      sx={{
        width: [(1 / 2) * 100 + "%", , , (1 / 4) * 100 + "%"],
        justifyContent: "center",
      }}
      {...props}
    >
      <Link to={to}>
        <div
          sx={{
            width: "100%",
            maxWidth: fluid.presentationWidth,
          }}
        >
          <GatsbyImage fluid={fluid} />
        </div>
      </Link>
    </Col>
  )
}

const Footer = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "Logos/EMM_Footer.png" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 85) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      vrettaLogo: file(relativePath: { eq: "Logos/Vretta_Footer.png" }) {
        childImageSharp {
          fluid(maxWidth: 148, quality: 85) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      AEE_Logo: file(relativePath: { eq: "awards/AEE_Logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 283, quality: 85) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      Brandon_Hall_Awards: file(
        relativePath: { eq: "awards/Brandon_Hall_Awards.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 162, quality: 85) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      Ed_Tech_Awards: file(relativePath: { eq: "awards/Ed_Tech_Awards.png" }) {
        childImageSharp {
          fluid(maxWidth: 375, quality: 85) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      IECG_Logo: file(relativePath: { eq: "awards/IECG-Logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 223, quality: 85) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)

  return (
    <footer sx={{ bg: "blackAlt", color: "greyLight", pt: [4, , , 5] }}>
      <Container {...props}>
        <Row sx={{ borderBottom: t => `3px solid ${t.colors.black}`, mb: 4 }}>
          <Col
            sx={{ width: ["100%", , , (2 / 3) * 100 + "%"], mb: [3, , , 0] }}
          >
            <SocialLinks />
          </Col>
          <Col sx={{ width: ["100%", , , (1 / 3) * 100 + "%"] }}>
            <P2 sx={{ fontWeight: "bold" }}>INFO@ELEVATEMYMATHS.CO.UK</P2>
          </Col>
        </Row>
        <Row sx={{ justifyContent: "space-between" }}>
          <Col sx={{ width: ["100%", , , (7 / 12) * 100 + "%"] }}>
            <div
              sx={{
                width: "100%",
                maxWidth: data.logo.childImageSharp.fluid.presentationWidth / 2,
                mb: 4,
              }}
            >
              <GatsbyImage fluid={data.logo.childImageSharp.fluid} />
            </div>
            <P2>
              {`Elevate My Maths (EMM) was designed and developed by an award-winning education technology company, `}
              <Link to="https://www.vretta.com" sx={{ color: "teal" }}>
                Vretta
              </Link>
              {`, in 2014. Since then, it has been used extensively to upgrade the maths skills of students and teacher-candidates in Canada, USA, UK, Sweden, and Australia.`}
            </P2>

            <P2>
              {`The unique assessment for learning model of EMM has received numerous international accolades from the Brandon Hall Group (USA), the e-Assessment Association (UK), International Education Games Competition (Europe), and The EdTech Awards (USA) for the best formative assessment, the best transformational project, and the best advance in competencies and skills development.`}
            </P2>

            <Row sx={{ justifyContent: "center", alignItems: "center" }}>
              <AwardImgCol
                to="https://www.vretta.com/blog_detail/blog_detail_48.php"
                fluid={data.Brandon_Hall_Awards.childImageSharp.fluid}
              />
              <AwardImgCol
                to="https://www.vretta.com/blog_detail/blog_detail_46.php"
                fluid={data.AEE_Logo.childImageSharp.fluid}
              />
              <AwardImgCol
                to="https://edtechdigest.com/the-edtech-awards/"
                fluid={data.Ed_Tech_Awards.childImageSharp.fluid}
              />
              <AwardImgCol
                to="https://mathematic.org/blog/post11"
                fluid={data.IECG_Logo.childImageSharp.fluid}
              />
            </Row>
          </Col>
          <Col sx={{ width: ["100%", , , (1 / 3) * 100 + "%"] }}>
            <div
              sx={{
                width: "100%",
                maxWidth:
                  data.vrettaLogo.childImageSharp.fluid.presentationWidth,
                mb: 4,
              }}
            >
              <GatsbyImage fluid={data.vrettaLogo.childImageSharp.fluid} />
            </div>
            {addresses.map(({ title, address }) => (
              <Location key={title} title={title} address={address} />
            ))}
          </Col>
        </Row>
      </Container>
      <div sx={{ bg: "black", color: "grey", py: 4 }}>
        <Container>
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", , , "row"],
              alignItems: "center",

              "& > a": {
                mb: [3, , , 0],
              },

              "& > a:last-child": {
                mb: 0,
              },
            }}
          >
            {legalLinks.map((item, index) => (
              <React.Fragment key={item.text}>
                <Link to={item.to}>{item.text}</Link>
                {legalLinks.length - 1 !== index && (
                  <span sx={{ mx: 2, display: ["none", , , "inline"] }}>|</span>
                )}
              </React.Fragment>
            ))}
            <Link
              sx={{ color: "teal", ml: [0, , , 3] }}
              to="https://www.vretta.com"
            >
              &copy;2020 Vretta Inc.
            </Link>
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
