import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Footer from "../components/Footer"
import ContactFormButton from "../components/ContactFormButton"

// import "normalize.css"
import { Global } from "@emotion/core"

const Layout = ({ children, ...props }) => {
  return (
    <React.Fragment>
      <Global
        styles={theme => ({
          ["*"]: { boxSizing: `border-box` },
          body: {
            fontFamily: "Quicksand",
            color: theme.colors.black,
            overflowX: "hidden",
          },
          a: {
            color: "currentColor",
          },
        })}
      />
      <main sx={{ mb: "8px", mx: "8px", position: "relative" }} {...props}>
        {children} <ContactFormButton />
        <Footer />
      </main>
    </React.Fragment>
  )
}

export default Layout
