import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import ButtonClean from "./ButtonClean"
import Dialog from "./Dialog"
import { FaEnvelope } from "react-icons/fa"
import { MdClose } from "react-icons/md"

import { P1 } from "./Typography"
import { InputText } from "./InputText"
import { ButtonSolid } from "./ButtonSolid"
// import axios from "axios"

const Input = ({ ...props }) => <InputText sx={{ mb: 4 }} {...props} />

const Form = ({ onSuccess, ...props }) => {
  const startingState = {
    name: "",
    email: "",
    college: "",
    comments: "",
  }

  const [data, setState] = React.useState(startingState)

  const updateVals = e => {
    setState({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    const encode = data => {
      return Object.keys(data)
        .map(
          key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&")
    }

    const submitData = encode({ "form-name": "contact", ...data })
    fetch("/?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: submitData,
    })
      .then(res => {
        setState(startingState)
        onSuccess()
        // eslint-disable-next-line
        console.log("Success:", res)
        return true
      })
      .catch(err => {
        // eslint-disable-next-line
        return console.error("Error:", err)
      })
  }

  return (
    <form
      {...props}
      onSubmit={e => handleSubmit(e)}
      name="contact"
      sx={{ textAlign: "center" }}
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <Input
        name="name"
        value={data.name}
        placeholder="Full Name *"
        onChange={updateVals}
        required
      />
      <Input
        name="email"
        type="email"
        value={data.email}
        placeholder="Email Address *"
        onChange={updateVals}
        required
      />
      <Input
        name="college"
        value={data.college}
        placeholder="Institution/Organization*"
        onChange={updateVals}
        required
      />
      <Input
        name="comments"
        as="textarea"
        sx={{ height: 100, border: `3px solid` }}
        value={data.comments}
        placeholder="Comments"
        onChange={updateVals}
        required
      />
      <ButtonSolid type="submit" sx={{ width: "100%" }}>
        SUBMIT
      </ButtonSolid>
    </form>
  )
}

const ContactFormButton = ({ ...props }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <ButtonClean
      onClick={() => setOpen(true)}
      sx={{
        bg: "teal",
        position: "fixed",
        bottom: 0,
        right: 0,
        color: "white",
        textAlign: "center",
        p: 2,
      }}
      {...props}
    >
      <P1 sx={{ fontSize: ["8px", "8px", "8px", "8px"], mb: 2 }}>CONTACT</P1>
      <div sx={{ fontSize: "3em", position: "relative" }}>
        <FaEnvelope
          sx={{ transition: `0.4s ease-in-out opacity`, opacity: open ? 0 : 1 }}
        />
        <MdClose
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            transition: `0.4s ease-in-out opacity`,
            opacity: open ? 1 : 0,
          }}
        />
      </div>
      <Dialog
        open={open}
        close={() => setOpen(false)}
        aria-label="Contact form opening and closing button"
      >
        <Form onSuccess={() => setOpen(false)} />
      </Dialog>
      {!open && <Form sx={{ display: "none" }} />}
    </ButtonClean>
  )
}

export default ContactFormButton
