/** @jsx jsx */
import { jsx } from "theme-ui"

export const InputText = ({ name, placeholder, as = "input", ...props }) => {
  const El = as
  return (
    <El
      name={name}
      placeholder={placeholder || name}
      aria-label={name}
      type={as}
      sx={{
        "&::placeholder": {
          color: "grey",
          textTransform: "capitalize",
          fontFamily: "Quicksand",
          fontWeight: "bold",
        },
        color: "currentColor",
        border: "none",
        borderBottom: `3px solid`,
        borderColor: "blackAlt",
        borderRadius: "0",
        display: "inline-block",
        mb: 3,
        height: 40,
        fontStyle: "italic",
        fontSize: 18,
        pl: 3,
        bg: "transparent",
        width: "100%",
      }}
      {...props}
    />
  )
}
