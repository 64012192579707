import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"
import { Global } from "@emotion/core"
import { FaTimes } from "react-icons/fa"
import ButtonClean from "./ButtonClean"

const Modal = ({ open, close, children, width, ...props }) => {
  return (
    <React.Fragment>
      <Global
        styles={theme => ({
          "[data-reach-dialog-content]": {
            width: "fit-content",
            padding: 0,
            margin: `0 auto 0`,
            position: "relative",
            [theme.mq[2]]: {
              margin: `${theme.sizes.navDesktop + theme.space[4]}px auto 0`,
            },
          },
          "[data-reach-dialog-overlay]": {
            zIndex: theme.zIndices.nav + 100,
          },
        })}
      />
      <Dialog isOpen={open} onDismiss={close} {...props}>
        <ButtonClean
          sx={{ position: "absolute", top: 3, right: 3 }}
          aria-label="close"
          onClick={close}
        >
          <FaTimes sx={{ fontSize: "2em" }} />
        </ButtonClean>
        <div
          sx={{
            maxWidth: "100vw",
            width: width || 500,
            p: 4,
            pt: 5,
          }}
        >
          {children}
        </div>
      </Dialog>
    </React.Fragment>
  )
}

export default Modal
