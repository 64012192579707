/** @jsx jsx */
import { jsx } from "theme-ui"
import Link from "./Link"
import { P1 } from "./Typography"
import ButtonClean from "./ButtonClean"

export const ButtonSolid = ({ children, to, ...props }) => {
  const El = to === undefined ? ButtonClean : Link
  return (
    <El
      to={to}
      sx={{
        bg: "teal",
        color: "white",
        minWidth: 240,
        px: 3,
        height: [50, , , , 60],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0 0 rgba(0,0,0,0.2)",
        cursor: "pointer",
        mb: 3,
        mt: 1,
        transition: `0.16s ease-in-out box-shadow`,
        "&:hover": {
          boxShadow: "4px 4px rgba(0,0,0,0.2)",
        },
      }}
      {...props}
    >
      <P1 sx={{ mb: `-0.3em` }}>{children}</P1>
    </El>
  )
}
