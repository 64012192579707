/** @jsx jsx */
import { jsx } from "theme-ui"
export const ButtonClean = ({ ...props }) => (
  <button
    sx={{
      border: "none",
      bg: "transparent",
      color: "currentColor",
      cursor: "pointer",
      px: 0,
    }}
    {...props}
  />
)

export default ButtonClean
