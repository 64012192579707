// import React from "react"
import { Link as GatsbyLink } from "gatsby"
/** @jsx jsx */
import { jsx } from "theme-ui"

const Link = ({ children, to, activeClassName, ...props }) => {
  const path = to
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  const mailTo = /^mailto/.test(to)
  const file = /\.[0-9a-z]+$/i.test(to)

  const style = {
    textDecoration: "none",
  }

  // Use Gatsby Link for internal links, and <a> for others
  if (internal && !file) {
    return (
      <GatsbyLink
        to={path}
        activeClassName={activeClassName}
        sx={{ ...style }}
        {...props}
      >
        {children}
      </GatsbyLink>
    )
  } else if (mailTo) {
    return (
      <a href={to} sx={{ ...style }} {...props}>
        {children}
      </a>
    )
  } else {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ ...style }}
        {...props}
      >
        {children}
      </a>
    )
  }
}

export default Link
